.bg-gradient-info {
  background: linear-gradient(87deg, #FE8B0A 0, #FE8B0A  100%) !important
}

.bg-yathra-secondary {
  background-color: #021341 !important;
}

.yathra-text-secondary {
  color: #021341;
}

.loginHeader {
  display: flex;
  justify-content: center;
}

.dropdown {
  width: 100%;
}

.dropdown-toggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-menu {
  width: 100%;
}

.actionTh {
  text-align: center;
  justify-content: space-between;
}

.actionTd {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.actionTd Button {
  /* padding: 0.3125rem .5125rem !important; */
  font-size: 1rem;
}

.paymentSection, .downloadSection {
  display: flex;
  justify-content: flex-end;
}

.exportBtn {
  padding: 0.3rem 0.6rem;
  margin-top: 10px;
  width: 100px;
}

.noDataImage {
  margin: auto;
  width: 20rem;
  height: auto;
}

.headerSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.paymentSection {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.paymentRecordsDownload {
  display: flex;
  justify-content: flex-end;
}

.paymentRecordsDownloadMonthly {
  display: flex;
  justify-content: flex-end;
}

.dateSelector {
  border-radius: 8px;
  border: 1px solid #cad1d7;
  color: #616466;
}

.react-datepicker__calendar-icon{
  color: #616466 !important;
}

.headerSection {
  display: flex;
}

.btnSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.monthBtn {
  padding: 0.3rem 0.6rem;
}

.wordingSection{ 
  display: flex;
  gap: 1rem;
}

.transationFilterButton {
  width: 232px !important;
}

.expenseSummaryHeader {
  display: flex;
  flex-direction: row;
}

.expenseSummaryDropdown {
  text-align: right;
}

.expenseSummaryHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.expenseSummaryDropdown {
  justify-content: flex-end;
}

.headerTitle {
  padding-bottom: 1.25rem;
}
@media screen and (min-width: 768px) and (max-width: 890px) {
  .headerSection {
    flex-direction: column;
    align-items:flex-start;
  }
  .paymentRecordsExport {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .paymentSection {
    align-items: flex-start;
  }
  .paymentRecordsDownload {
    justify-content: flex-start;
  }
  .paymentRecordsDownloadMonthly {
    justify-content: flex-start;
    width: 240px;
  }
  .wordingSection{
    padding-left: 0px;
    margin-bottom: 10px;
  }
  .headerSection {
    flex-direction: column;
    gap: 10px;
  }
  .btnSection{
    align-items: flex-start;
  }
  .headerSectionPayment {
    display: flex;
    gap: 10px;
  }
}
@media screen and (max-width: 576px) {
  .headerSection {
    flex-direction: column;
    align-items:flex-start;
  }
  .paymentRecordsExport {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .paymentSection {
    align-items: flex-start;
  }
  .paymentRecordsDownload {
    justify-content: flex-start;
  }
  .paymentRecordsDownloadMonthly {
    justify-content: flex-start;
    width: 240px;
  }
  .wordingSection{
    padding-left: 0px;
    margin-bottom: 10px;
  }
  .headerSection {
    flex-direction: column;
    gap: 10px;
  }
  .btnSection{
    align-items: flex-start;
  }
}

.dropdownMenuStyle {
  max-height: 400px;
  overflow-y: auto;
}

.toggleSection {
  display: flex;
  gap: 1rem;
}

.creditTransaction {
  background-color: rgb(252, 221, 221);
}

.debitTransaction {
  background-color: rgb(221, 245, 221);
}

.creditTransaction1 {
  background-color: rgb(203, 243, 203);
}

.messageBox {
  height: 50px;
}

.msgSendBtn {
  height: 100%;
}

.messageBox .form-group {
  margin-bottom: 0px !important;
}

.classFeesBalanceRow {
  border-spacing: 10px;
}
