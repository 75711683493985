/* @media print {
    @page {
        size: 210mm 297mm;
        margin: 10mm;
    } */

    .receipt {
        width: 210mm;
        display: grid;
        grid-template-columns: auto;
    }
    
    .receiptPaper {
        display: flex;
        page-break-inside: avoid;
        break-inside: avoid;
    }
    
    .barcodeCard {
        box-sizing: border-box;
        padding: 10px;
        width: 100mm;
        height: 60mm;
        border: 1px solid black !important;
        margin: 7mm 2mm 6mm 2mm;
    }
    
    .qrCode {
        width: 90px !important;
        height: 90px !important;
    }
    
    .barCode {
        width: 170px;
        height: auto;
    }
    
    .studentDetails {
        width: 80mm;
    }
    
    .qrRow {
        height: 33mm !important;
        display: flex;
    }
    
    .barcodeRow {
        display: flex;
    }
    
    .barCode {
        width: 50mm;
        height: auto;
    }
    
    .yathra {
        font-size: 28px;
        margin-bottom: 0px;
        line-height: 1.3;
    }
    
    .hdc {
        font-size: 13px;
        margin-bottom: 0px;
    }
    
    .bmr {
        font-size: 10px;
        margin-bottom: 10px;
    }
    
    .stDetails {
        margin-bottom: 0px;
    }
    /* } */    