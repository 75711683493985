.custom-toggle {
  width: 110px;
  height: 2rem;
}

.custom-toggle-slider::before {
  height: 1.7rem;
  width: 35px;
  border-radius: 20px !important;
  bottom: 0.1rem;
}

.custom-toggle input:checked+.custom-toggle-slider::before {
  background: #4ab7e0;
  transform: translateX(4.2rem);
}

.present {
  padding-left: 10px;
  padding-top: 2px;
}

.absent {
  padding-left: 45px;
  padding-top: 2px;
}

.toggle-dis {
  padding-left: 42px;
  padding-top: 2px;
}
.custom-toggle input+.custom-toggle-slider {
  border: 1px solid #f31010;
}

.custom-toggle input+.custom-toggle-slider::before {
  background: #f31010;
}

.toggle-section {
  display: flex;
  justify-content: space-between;
}

@media  screen and (max-width: 576px) {
  label .toggle-label {
    font-size: 12px !important;
  }

  .toggle-section {
    align-items: center;
  }
}